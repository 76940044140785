import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBriE5-DvpfiGVU3dUNei5mNXTjXII5JlM",
  authDomain: "napadle.firebaseapp.com",
  projectId: "napadle",
  storageBucket: "napadle.appspot.com",
  messagingSenderId: "5091353458",
  appId: "1:5091353458:web:041e51bb2bcf9cc59fb3eb",
  measurementId: "G-G6X6156XRE"
};
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export const db = getFirestore(app);

export default app;