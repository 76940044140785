import './App.css';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { auth,db } from './firebase.config'
import { signInWithEmailAndPassword, deleteUser } from "firebase/auth";
import { useState } from 'react';
import { collection, doc, setDoc } from "firebase/firestore"; 


function App() {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const usersRef = collection(db, "users");

  const handleSubmit = async (event) => {
    
    event.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
          const user = userCredential.user;
          console.log(user);
          
          await deleteUser(user).then(async (value) => {
            console.log(value)
           
            await setDoc(doc(usersRef, user.uid), {
              "deleted": true},
              { merge: true }).then((value) => {
                console.log(value)
                alert("A sua conta foi removida com sucesso")
              }).catch((error) => {
                console.log(error)
              });
            });
          })
          .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode, errorMessage)
      })
  
      .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode, errorMessage)
      });
     
    } catch (error) {
      console.error("Error deleting user: ", error);
      alert(error.message);
    }
  };



  return (
    <div className="App">
      
      <header className="App-header">
      <h1>Remover Conta Great Sports</h1>
      <Form style={{"paddingTop" : "40px"}}  onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control type="email" value={email} onChange={(e) => setEmail(e.target.value)}  />
        <Form.Text className="text-muted">
          """""""""""""""""""""""""""""""""""""
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control type="password" value={password} onChange={(e) => setPassword(e.target.value)}   />
      </Form.Group>
      
      <Button variant="primary" type="submit">
        Remover
      </Button>
    </Form>
      </header>
    </div>
  );
}

export default App;
